body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

strong {
	font-weight: bold !important;
}

.ui.card .content .cropper-canvas img {
	display: block;
}

.ui.sub.header {
	font-size: 20px !important;
}
.form-footer {
	background: #ccd1d6;
	border: 1px solid #bdc4cd;
	padding: 20px;
	margin: 0 -21px -21px -21px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.no-items-found {
	color: rgba(0, 0, 0, 0.6);
	font-style: italic;
	text-align: center;
}

.rrt-ok-btn {
	color: #d64f1b;
}
.rrt-confirm-holder
	.rrt-confirm
	.rrt-buttons-holder
	.rrt-button.rrt-ok-btn:active {
	background-color: #d64f1b !important;
	color: white;
}
.rrt-confirm-holder
	.rrt-confirm
	.rrt-buttons-holder
	.rrt-button.rrt-cancel-btn:active {
	background-color: #ccc !important;
	color: #333 !important;
}
.rrt-message:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: '\f071';
	color: #d64f1b;
	padding: 0 0 10px 0;
	font-size: 20px;
	display: block;
}
.rrt-message {
	background: #d6501b1c !important;
}

.ui.segment.segment-wrapper.segment-wrapper {
	margin: 30px;
	padding: 0;
	border: none;
}
.ui.segment {
	position: inherit !important;
}

.ui.icon.button {
	margin: 2px;
}

div.SingleDatePickerInput,
div.SingleDatePicker,
div.SingleDatePicker .DateInput {
	width: 100%;
}

.rc-time-picker {
	width: 100%;
}

input.rc-time-picker-input {
	height: 100%;
}
div.rc-time-picker-panel-input-wrap {
	font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
	margin: 0;
	outline: 0;
	line-height: 1.21428571em;
	padding: 0.67857143em 1em;
	font-size: 1rem;
	background: #fff;
	border: 1px solid rgba(34, 36, 38, 0.15);
	color: rgba(0, 0, 0, 0.87);
	border-radius: 0.28571429rem;
	box-shadow: 0 0 0 0 transparent inset;
}

table .ui.input > input {
	width: inherit;
}

.carousel-track {
	height: 300px !important;
}

.ui.table {
	border: none;
	border-radius: 4px;
}

.ui.table tfoot td {
	border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.ui.card {
	margin: 0;
}

.not-mobile {
	display: inherit;
}

.price-column {
	border-left: 1px solid rgba(34, 36, 38, 0.15);
}

.bold-text {
	color: #333;
	font-weight: bold;
}

.mb-1 {
	margin-bottom: 1em !important;
}

.no-top-border {
	border-top: 0 !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

@media (max-width: 1024px) {
	.not-mobile {
		display: none !important;
	}
}

.react-datepicker__input-container input {
	border-radius: 0.3rem;
	border: 1px solid #dededf;
	/* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5); */
	padding: 0.7rem 0.7rem;
}

.react-datepicker__input-container input:focus {
	outline: none;
	border: 1px solid #96c8da;
}

.react-datepicker__input-container input::placeholder {
	opacity: 0.4;
}
.react-datepicker__input-container input:hover {
	outline: none;
	border: 1px solid #b3bac2;
}
